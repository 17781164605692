import React from 'react';
import {ModuleLayout} from "../layouts/ModuleLayout";

export const Services = (props) => {
    return (
        <ModuleLayout {...props}>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-8 lg:max-w-none max-w-[500px] m-auto'>
                <div className='shadow-lg shadow-red-300/20 rounded bg-gradient-to-b from-gray-100/5 to-primary-200/30 p-6 border-t-8 border-red-300 space-y-3'>
                    <div className='text-lg text-heading dark:text-heading-dark uppercase font-bold'>Bespoke Developer</div>
                    <p>Customize your ideal IT developer with relevant technical skills from a pool of 176 hours per month.</p>
                    <p>Our clients' top three most sought-after IT developers are:</p>
                    <ul className='list-disc marker:text-red-400 dark:marker:text-red-300 pl-8 space-y-1.5 !mt-1'>
                        <li><span className='font-medium'>Webapp development</span>: Bespoke developer = 50% frontend + 30% backend + 10% tester + 10% devops</li>
                        <li><span className='font-medium'>Adding a new feature</span>: Bespoke developer = 20% UX/UI design + 20% backend + 20% frontend + 20% tester + 20% devops</li>
                        <li><span className='font-medium'>App enhancement</span>: Bespoke developer = 30% UX/UI design + 10% backend + 30% frontend + 20% tester + 10% devops</li>
                    </ul>
                </div>
                <div className='shadow-lg shadow-primary/20 dark:shadow-primary-dark/20 rounded bg-gradient-to-b from-gray-100/5 to-primary-200/30 p-6 border-t-8 border-primary dark:border-primary-dark xl:scale-105 space-y-3'>
                    <div className='text-lg text-heading dark:text-heading-dark uppercase font-bold tracking-wide'>Minimum Viable Product (MVP)</div>
                    <p>Starting from $15K, you may have your MVP with the following features:</p>
                    <ul className='list-disc marker:text-primary-400 dark:marker:text-primary-400 pl-8 space-y-1.5 !mt-1'>
                        <li>Up to 20 web application pages</li>
                        <li>Cloud-based infrastructure</li>
                        <li>Features for authentication and authorization</li>
                        <li>Environments for development and production</li>
                        <li>UI/UX, testers, project management, and so forth are all included</li>
                        <li>There are no hidden fees</li>
                    </ul>
                </div>
                <div className='shadow-lg shadow-green-500/20 rounded bg-gradient-to-b from-gray-100/5 to-primary-200/30 p-6 border-t-8 border-green-500 space-y-3'>
                    <div className='text-lg text-heading dark:text-heading-dark uppercase font-bold tracking-wide'>End-to-end Solutions</div>
                    <p>Are you starting a new business or growing an existing one and want to increase the capacity of your
                        technological system?</p>
                    <p>We have extensive expertise building and updating our clients' IT systems to meet their business requirements at affordable
                        pricing.</p>
                    <ul className='list-disc marker:text-green-500 dark:marker:text-green-500 pl-8 space-y-1.5'>
                        <li><span className='font-medium'>Frontend</span>: JavaScript, ReactJS, React Native, NextJS, Gatsby, and ElectronJS</li>
                        <li><span className='font-medium'>Backend</span>: NodeJS, Java, Python, and Typescript</li>
                        <li><span className='font-medium'>Cloud services</span>: Amazon Web Services, Google Cloud, and Microsoft Azure</li>
                        <li>Others include infrastructure, testing, devops, and UI/UX design</li>
                    </ul>
                </div>
            </div>
        </ModuleLayout>
    );
};
