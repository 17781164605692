import React from "react";

export const Thumbnail = ({src, name}) => <img
    src={src} alt={name}
    className={`w-28 h-28 dark:border dark:border-gray-700 bg-gradient-to-b from-gray-900/5 dark:from-gray-500 to-primary/20 shadow-inner rounded-full bg-cover bg-center object-cover brightness-125`}
/>;

export const ThumbnailSmall = ({src, name}) => <img
    src={src} alt={name}
    className={`w-20 h-20 dark:border dark:border-gray-700 border border-gray-500/10 shadow-inner rounded-full bg-cover bg-center object-cover brightness-125`}
/>;
