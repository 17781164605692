export const ProjectsData = [
    {
        project_name: 'Mobile Parking',
        visible: true,
        describe: 'Mobile Parking is the best way to find and pay for parking, or to reserve a parking spot in advance of your arrival. Whether you\'re heading to a local restaurant or sporting event, or just driving around in your town, Mobile Parking helps you to find the best parking in real-time manner.',
        technical: [
            'Front-end: Webapp (React), iOS and Android (React Native)',
            'Back-end: Java, Spring Framework, NodeJS, RESTFul API, JWT, Nginx, Load Balancing',
            'Database: MySQL, Elastic Search, RabbitMQ'
        ]
    },
    {
        project_name: 'Project Brave',
        visible: true,
        describe: 'Brave is a project management tool used for project planning, resource allocation, and scheduling by a variety of businesses. Project management allows project managers and other stakeholders to have real-time control over their budgets, quality management, and all paperwork.',
        technical: [
            'Front-end: iOS and Android (React Native)',
            'Back-end: NodeJS, LoopBack Framework, RESTFul API, JWT, Nginx, Load Balancing',
            'Database: MySQL'
        ]
    },
    {
        project_name: 'IOT for Water Detection',
        visible: true,
        describe: 'Water detection is a network of sensors that allows the environment team to measure water volume from its sewage system in real-time throughout the rainy season. The application enhances the efficiency of drainage system management, planning, and operation, as well as providing information on the present status of the drainage system and the flooding scenario.',
        technical: [
            'Front-end: Webapp (React, Bootstrap)',
            'Back-end: NodeJS, LoopBack Framework, RESTFul API, JWT, Nginx',
            'Database: MongoDB'
        ]
    },
    {
        project_name: 'Local News',
        visible: false,
        describe: 'Local News is an app that delivers the best news from around the city! It acts as a one stop resource for expats, giving them all the information they need to live the best life like never before. Local News has been made easier than ever to use thanks to its intuitive interface and features.',
        technical: [
            'Front-end: Webapp (React), iOS and Android (React Native)',
            'Back-end: NodeJS, LoopBack Framework, RESTFul API, JWT, Nginx',
            'Database: MySQL'
        ]
    },
    {
        project_name: 'Production Booster Application',
        visible: true,
        describe: 'The Production Booster Application is a real-time monitoring and improvement solution for any operator-led manufacturing process. The Production Booster Application is a complete solution that includes setup, input, and analysis views to benefit all users, including operators, team leaders, engineers, and managers.',
        technical: [
            'Front-end: Webapp (NextJS Framework, React), iOS and Android (React Native)',
            'Back-end: NodeJS, NestJS Framework, LoopBack Framework, RESTFul API, JWT, SocketIO',
            'Database: MySQL, Elastic Search, Redis Cache and Queue',
            'Embedded: Arduino, Bluetooth Low Energy',
        ]
    },
];
