import React from 'react';

export const ProjectItem = (item) => {
    const {project_name, describe, technical = []} = item;
    return (
        <div className='shadow-lg shadow-gray-200/60 dark:shadow-gray-700 p-6 border-[1px] border-gray-200/60 dark:border-gray-700 rounded-lg space-y-3 lg:max-w-none max-w-[500px] m-auto lg:m-0'>
            <p className='text-lg text-heading dark:text-heading-dark font-bold uppercase tracking-wide'>{project_name}</p>
            <div className='w-14 h-1 bg-gradient-to-r from-primary to-primary/60 dark:from-primary-dark dark:to-primary-dark/60'/>
            <p className='leading-7'>{describe}</p>
            <p className='!mt-5'>Key technical solutions:</p>
            <ul className='list-disc marker:text-primary-400 dark:marker:text-primary-400 pl-8 space-y-1.5 !mt-1'>
                {technical.map((item, index) => <li key={index}>{item}</li>)}
            </ul>
        </div>
    );
};
