import React from 'react';
import {Services} from "../../components/Services";
import {Banner} from "../../components/Banner";
import {Team} from "../../components/Team";
import {Projects} from "../Projects/Projects";
import {Testimonial} from "../../components/Testimonial";
import {baTracker} from "../../services/tracking/Mixpanel";
import {useConstants} from "../../utils/constants";

export const HomePage = ({path}) => {
    const {videoYoutubeTestimonialByAnthonyId, meetingUsUrl} = useConstants();
    baTracker.track({name: path, pageName: 'Home'});
    return (
        <>
            <section id='home'>
                <Banner url={meetingUsUrl}/>
                <Testimonial title={'What are our customers saying about us?'} videoYouTubeId={videoYoutubeTestimonialByAnthonyId}/>
            </section>
            <section id='services'>
                <Services title='Our Services'/>
            </section>
            <section id='team'>
                <Team title={'Core Team'} hasBackground/>
            </section>
            <section id='projects'>
                <Projects title={'Featured Projects'}/>
            </section>
        </>
    );
};
