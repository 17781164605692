import React from 'react';
import BespokeDevelopers from '/src/images/bespoke_developers.png';
import {ButtonGetStarted} from "./Button";

export const Banner = ({url}) => {
    return (
        <div className='bg-gradient-to-bl from-gray-100/5 via-primary-300/50 to-primary-200/1 md:pt-[100px] pt-[70px]'>
            <div className='flex container mx-auto xl:flex-row flex-col md:space-x-2 px-[1rem]'>
                <div className='flex flex-1 flex-col xl:justify-center xl:items-start items-center mt-[50px] space-y-4'>
                    <p className='text-3xl font-bold text-center md:text-left'>
                        <span className='text-title dark:text-title-dark'>Flexible</span> way to get your job <span className='text-title dark:text-title-dark'>done!</span>
                    </p>
                    <div className='text-lg text-center xl:text-left space-y-3'>
                        <p>Customize and hire our "bespoke developers" to take care of your business!</p>
                        {/*<p>Starting from $4,000/month.</p>*/}
                    </div>
                    <ButtonGetStarted url={url}/>
                    <div className='!mt-12 italic text-sm opacity-80 text-center xl:text-left'>
                        <p>"Opportunities are like sunrises. If you wait too long, you miss them."</p>
                        <p className='flex justify-end text-center font-light md:text-left'>- William Arthur Ward -</p>
                    </div>
                </div>
                <div className='flex flex-1 mt-[50px] justify-center'>
                    <img src={BespokeDevelopers} className='min-h-[20vmin] bg-cover object-cover bg-top brightness-[1.15]' width="586px" height="383px" alt='Bespoke Developers'/>
                </div>
            </div>
        </div>
    );
};

