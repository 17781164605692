import React from "react";
import classNames from "classnames";

// Used: Projects, Services, Team
export const ModuleLayout = ({children, title, hasBackground, style = {}}) => {
    const classRoot = classNames(
        {'bg-gradient-to-br from-gray-100/5 via-primary-300/30 to-primary-200/5': hasBackground,}
    );
    return (
        <div className={classRoot}>
            <div className='container mx-auto py-10 md:py-16 px-4' style={style}>
                <p className='text-2xl md:text-3xl text-title dark:text-title-dark font-bold text-center mb-8 md:mb-14'>{title}</p>
                {children}
            </div>
        </div>
    );
};
