import React from 'react';
import ReactPlayer from 'react-player/lazy';
import {IoLogoLinkedin, IoMdGlobe, IoIosPlay} from "react-icons/io";
import AnthonyCahill from '../images/anthony-cahill.png';
import {ModuleLayout} from "../layouts/ModuleLayout";
import {ThumbnailSmall} from "./Thumbnail";

const IS_IFRAME = false;

// rel=0: only show list of same source after and video
// modestbranding=1: hide Youtube logo, it only ok if color=red or unset
// color=white: color of progress bar
const getYoutubeUrlForIFrame = (videoId) => `https://www.youtube.com/embed/${videoId}?rel=0&modestbranding=1&color=white&autoplay=0`;
const getYoutubeUrl = (videoId) => `https://www.youtube.com/embed/${videoId}`;

export const Testimonial = ({videoYouTubeId, title, hasBackground}) => {
    return (
        <ModuleLayout {...{title, hasBackground}} style={{paddingBottom: 0}}>
            <div className='block space-y-6 lg:space-y-0 lg:flex xl:space-x-16 lg:space-x-10 lg:max-w-none max-w-[500px] m-auto'>
                <div className="flex-1 flex flex-col justify-center">
                    <div className="shadow-md shadow-primary-700/50 aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                        {IS_IFRAME ? <iframe
                                title={'Testimonial Video'}
                                src={getYoutubeUrlForIFrame(videoYouTubeId)}
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                className="rounded-lg overflow-hidden"
                            /> :
                            <ReactPlayer
                                width={'100%'}
                                height={'100%'}
                                className='group rounded-lg overflow-hidden'
                                style={{}}
                                url={getYoutubeUrl(videoYouTubeId)}
                                controls
                                light
                                config={{
                                    youtube: {
                                        playerVars: {rel: 0, modestbranding: 1, color: 'white', autoplay: 1}
                                    },
                                }}
                                playIcon={
                                    <div className='bg-primary-700/10 w-full center h-full rounded-lg overflow-hidden brightness-[130%]'>
                                        <div className='rounded-full bg-gray-600/50 w-20 h-20 center'>
                                            <IoIosPlay className='group-hover:text-primary/50 dark:group-hover:text-primary-dark/50 text-white text-4xl ml-0.5'/>
                                        </div>
                                    </div>
                                }
                            />}
                    </div>
                </div>
                <div className='flex-1 flex flex-col justify-center space-y-5'>
                    <div className='flex items-center space-x-6'>
                        <ThumbnailSmall src={AnthonyCahill} name='Anthony Cahill'/>
                        <div className='flex-1 flex-col justify-center'>
                            <p className='font-medium'>Anthony Cahill</p>
                            <p className='text-sm font-light'>Founder and CEO at Kyzentree Technologies</p>
                            <p className='flex mt-3 space-x-1'>
                                <a className='text-link hover:text-link/70 dark:text-link-dark dark:hover:text-link-dark/70' title='Linkedin of Anthony Cahill' rel="noreferrer" href='https://www.linkedin.com/in/anthony-cahill-808823b/' target='_blank' aria-label="Linkedin of Anthony Cahill"><IoLogoLinkedin className='text-2xl'/></a>
                                <a className='text-link hover:text-link/70 dark:text-link-dark dark:hover:text-link-dark/70' title='Kyzentree Website' rel="noreferrer" href='https://www.kyzentree.com/' target='_blank' aria-label="Kyzentree Website"><IoMdGlobe className='text-2xl'/></a>
                            </p>
                        </div>
                    </div>
                    <div className='space-y-3'>
                        <p>"Leansoft is a highly qualified IT team with a broad range of skill sets and a very strong focus
                            on quality. Leansoft also has great communication skills and a rapid response rate to queries and
                            issues.</p>
                        <p>I would highly recommend Leansoft as an end-to-end software solution partner."</p>
                    </div>
                </div>
            </div>
        </ModuleLayout>
    );
};

// const HighLight = ({children}) => <span title={children} className='px-[1px] underline underline-offset-2 decoration-from-font decoration-dashed decoration-primary'>{children}</span>
