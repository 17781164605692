import React from 'react';
import kson from "../images/members/son.png";
import klam from "../images/members/lam.png";
import knguyen from "../images/members/nguyen.png";
import hvu from "../images/members/vu.png";
import thuyen from "../images/members/huyen.png";
import tnam from "../images/members/nam.png";
import {ModuleLayout} from "../layouts/ModuleLayout";
import {Thumbnail} from "./Thumbnail";

export const TeamsData = [
    {
        image: kson,
        fullName: 'Son Tran',
        job: 'Manager',
        hashtag: '#leadership #criticalthinking #teamwork'
    },
    {
        image: klam,
        fullName: 'Lam Le',
        job: 'Customer Success',
        hashtag: '#listener #reliable #problemsolving'
    },
    // {
    //     image: knguyen,
    //     fullName: 'Nguyen Doan',
    //     job: 'Developer',
    //     hashtag: '#committed #careful #dedicated'
    // },
    {
        image: hvu,
        fullName: 'Vu Nguyen',
        job: 'Developer',
        hashtag: '#logical #fastlearner #candomindset'
    },
    {
        image: thuyen,
        fullName: 'Huyen Nguyen',
        job: 'Developer',
        hashtag: '#detailoriented #patient #disciplined'
    },
    {
        image: tnam,
        fullName: 'Nam Nguyen',
        job: 'Developer',
        hashtag: '#creative #persistent #goodeyes'
    },
];

export const Team = (props) => {
    return (
        <ModuleLayout {...props}>
            <div className='lg:columns-3 md:columns-2 space-y-8 gap-8'>
                {TeamsData.map((item, index) => (
                    <div key={index} className='md:max-w-none max-w-[400px] m-auto shadow-lg dark:shadow-primary-800/50 flex flex-col sm:items-start items-center bg-white dark:bg-gray-800 rounded-lg p-6 break-inside-avoid-column'>
                        <div className='flex flex-col sm:flex-row items-center'>
                            <Thumbnail src={item.image} name={item.fullName}/>
                            <div className='flex flex-col items-center sm:items-start flex-1 mx-3 space-y-1 my-2'>
                                <p className='font-medium'>{item.fullName}</p>
                                <p className='text-sm font-light'>{item.job}</p>
                            </div>
                        </div>
                        <p className='text-sm text-primary-600 dark:text-primary-500 text-center sm:text-left opacity-80 mt-2 sm:mt-4'>{item.hashtag}</p>
                    </div>
                ))}
            </div>
        </ModuleLayout>);
};
