import React from 'react';
import {ModuleLayout} from "../../layouts/ModuleLayout";
import {ProjectsData} from "./Data";
import {ProjectItem} from "../../components/ProjectItem";

export const Projects = (props) => {
    return (
        <ModuleLayout {...props}>
            <div className='grid lg:grid-cols-2 gap-8'>
                {ProjectsData.filter(item => item.visible).map((item, index) => (
                    <ProjectItem {...item} key={index}/>
                ))}
            </div>
        </ModuleLayout>
    );
};
